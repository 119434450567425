import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import {ContentPage} from "../contentPage";
import {Pages} from '../../../config/pages';
import {Post} from "../../../common/post";
import {Link} from "../../../common/link";
import {formatStringDateLocalToHu} from "../../../common/contentAux";
import moment from "moment";
import * as styles from './esemenyekPage.module.scss';
import * as postStyles from "../../../common/post.module.scss";


const formatGalleryLink = (galleryLink) => 
    <Link 
        key={galleryLink.text}
        location={Pages.galeria.withCategory + galleryLink.category} 
        className={postStyles.galleryLink}>
        {galleryLink.text}
    </Link>;

const formatEventDates = (dateFromStr, dateToStr) => {
    const dateFromDisplay = formatStringDateLocalToHu(dateFromStr, 'LL');
    if (!dateToStr) {
        return dateFromDisplay;
    }

    const dateFrom = moment(dateFromStr);
    const dateTo = moment(dateToStr);

    if (dateFrom.year() !== dateTo.year()) {
        return `${dateFromDisplay} – ${dateTo.format('LL')}`;
    } else if (dateFrom.month() !== dateTo.month()) {
        return `${dateFromDisplay} – ${dateTo.format('MMMM D')}.`;
    } else {
        return `${dateFromDisplay.slice(0, dateFromDisplay.length-1)}–${dateTo.format('D')}.`;
    }
};

export default function EsemenyekPage() {
    const data = useStaticQuery(graphql`
        query EventsQuery {
            allMarkdownRemark(
                filter: {
                    fileAbsolutePath: {regex: "/(events)/"},
                    frontmatter: {dateFrom: {ne: null}}
                }, 
                sort: {fields: [frontmatter___dateFrom], order: DESC}) {
                edges {
                    node {
                        frontmatter {
                            title
                            dateFrom
                            dateTo
                            location
                            files {
                                title
                                file
                            }
                            galleryLink {
                                text
                                category
                            }
                        }
                        html
                    }
                }
            }
        }
    `);
  
    const events = data.allMarkdownRemark.edges;

    return (
        <ContentPage activePage={1}>
            <div className={styles.container}>
                {events.map(({node}, i) => {
                    const {frontmatter, html} = node;
                    const {title, dateFrom, dateTo, location, galleryLink, files} = frontmatter;

                    return (<Post 
                        key={i}
                        header1={title}
                        header2={formatEventDates(dateFrom, dateTo)}
                        header3={location} 
                        files={files}
                        html={html}
                        index={i}
                        links={galleryLink && [formatGalleryLink(galleryLink)]}
                    />)
                })}                      
          </div>
        </ContentPage>
    );
}
